import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

import theme from "../../../theme";
import mq from "../../../utils/mediaQuery";
import ContentWrapper from "../../ContentWrapper";
import { HEADER_HEIGHT } from "../../HeaderComponent/constants";
import Heading from "../../Heading";
import { bodyLarge } from "../../Typography";

const Intro = styled.p`
  ${bodyLarge}
  margin-bottom: ${theme.space("xl")};
  max-width: 600px;
`;

const StyledContentWrapper = styled(ContentWrapper)`
  margin-top: ${HEADER_HEIGHT}px;
  margin-bottom: 0;
`;

const TitleWrapper = styled.div`
  padding: ${theme.space("l")} 0;
  margin-bottom: ${theme.space("s")};

  ${mq("3")} {
    margin-bottom: ${theme.space("m")};
    padding-left: ${theme.space("xxl")};
    max-width: 700px;
  }
`;

function HeroV8({ title, introduction, ...props }) {
  return (
    <StyledContentWrapper columns={12} {...props}>
      <TitleWrapper>
        <Heading color="primary" size={1}>
          {title}
        </Heading>
      </TitleWrapper>
      {introduction && <Intro>{introduction}</Intro>}
    </StyledContentWrapper>
  );
}

HeroV8.propTypes = {
  title: PropTypes.node.isRequired,
  introduction: PropTypes.node,
};

HeroV8.defaultProps = {};

export default HeroV8;
